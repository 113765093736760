<div *ngIf="formReady" class="container-fluid location-edit-container" #locationEdit>
    <div class="error-group-holder" *ngIf="errors?.length">
        <div class="alert alert-danger">
            <h4 class="error-header">
                {{ 'One of the following data fields have errors:' | translate }}
            </h4>
            <div>
                <p *ngFor="let error of errors; let i = index">{{ i + 1 }}. {{ error | translate }}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form *ngIf="location" [formGroup]="form" novalidate autocomplete="off">
                <fieldset class="form-group-container location-information-container" [disabled]="readonly">
                    <legend>{{ 'Location Information' | translate }}</legend>
                    <div>
                        <div class="form-group col-md-12 business-name-field">
                            <label class="required-label" for="inputName">{{ 'Business Name' | translate }}</label>
                            <input type="text" class="form-control" autocomplete="nope" formControlName="name" />
                            <app-input-error-messages [control]="form.controls.name"></app-input-error-messages>
                        </div>
                        <div class="form-group col-md-12 business-name-field">
                            <label for="inputName">{{ 'Alias' | translate }}</label>
                            <input type="text" class="form-control" formControlName="alias" />
                            <app-input-error-messages [control]="form.controls.alias"></app-input-error-messages>
                        </div>
                        <div class="col-md-4 slm-input-image-category-holder">
                            <slm-input-image
                                [imageValue]="location.photos"
                                [control]="form.controls.photos"
                                [imageType]="'logo'"
                                [minHeight]="140"
                                [minWidth]="140"
                                [maxHeight]="600"
                                [maxWidth]="600"
                                [label]="'Logo'"
                                [locationId]="location.id"
                                [multipleValue]="false"
                                class="slm-input-image"
                            ></slm-input-image>
                            <slm-input-category
                                requiresOne="true"
                                [formControl]="form.controls.categories"
                                [control]="form.controls.categories"
                                [locations]="[location]"
                                publisherId="gmb"
                                class="app-input-category"
                            ></slm-input-category>
                            <app-input-category-publisher
                                *ngIf="hasPackConfig"
                                [isProspect]="isProspect"
                                [locations]="[location]"
                                requiresOne="true"
                                [formControl]="form.controls.publisher_specific"
                                [control]="form.controls.publisher_specific"
                                publisherId="pagesjaunes"
                                class="app-input-category"
                            ></app-input-category-publisher>
                        </div>
                        <div class="col-md-8 slm-input-address-holder">
                            <slm-input-address
                                [formControl]="form.controls.address"
                                [setCoordinates]="markerCoordinates"
                                [locationId]="location.id"
                                class="slm-input-address"
                            ></slm-input-address>
                        </div>
                        <legend class="location-edit-map-title">
                            {{ 'Map' | translate }}
                        </legend>
                        <div>
                            <slm-app-locations-map
                                [locations]="[location]"
                                [showPopups]="false"
                                [draggableMarker]="true"
                                (markerPlaced)="markerDragEnd($event)"
                            ></slm-app-locations-map>
                        </div>
                    </div>
                </fieldset>

                <fieldset class="form-group-container contact-data-container" [disabled]="readonly">
                    <legend>{{ 'Contact Data' | translate }}</legend>
                    <div>
                        <slm-input-phones
                            [formControl]="form.controls.phones"
                            [control]="form.controls.phones"
                            requiresOne="true"
                            checkForDuplicates="true"
                        ></slm-input-phones>
                        <slm-input-email
                            [formControl]="form.controls.emails"
                            checkForDuplicates="true"
                        ></slm-input-email>
                        <app-input-combined-urls [formControl]="form.controls.urls"></app-input-combined-urls>
                    </div>
                </fieldset>

                <fieldset class="form-group-container business-information-container" [disabled]="readonly">
                    <legend>{{ 'Business Information' | translate }}</legend>

                    <div class="form-group col-md-12 business-info">
                        <label for="description">{{ 'Description' | translate }}</label>
                        <textarea type="text" rows="6" class="form-control" formControlName="description"></textarea>
                        <span
                            class="character-counter"
                            [ngClass]="{
                                'length-warning': hasLengthExceeded(
                                    MAX_LENGTH_DESCRIPTION,
                                    form.controls.description.value.length
                                )
                            }"
                        >
                            <span
                                *ngIf="
                                    !hasLengthExceeded(MAX_LENGTH_DESCRIPTION, form.controls.description.value.length)
                                "
                                translate
                                >Characters left:</span
                            >
                            <span
                                *ngIf="
                                    hasLengthExceeded(MAX_LENGTH_DESCRIPTION, form.controls.description.value.length)
                                "
                                translate
                                >Characters exceeded:</span
                            >
                            {{
                                calculateCharactersLeft(MAX_LENGTH_DESCRIPTION, form.controls.description.value.length)
                            }}</span
                        >

                        <app-input-error-messages [control]="form.controls.description"></app-input-error-messages>
                    </div>
                    <div class="form-group col-md-12 business-info">
                        <app-input-service-area
                            [formControl]="form.controls.service_area"
                            [country]="location.address.country"
                        ></app-input-service-area>
                    </div>
                </fieldset>

                <fieldset class="form-group-container opening-hours-container" [disabled]="readonly">
                    <legend>{{ 'Opening Hours' | translate }}</legend>

                    <app-toggle-switch
                        [checked]="showOpeningHours"
                        (onchange)="toggleOpeningHours($event)"
                    ></app-toggle-switch>
                    <span class="toggle-title">{{ 'Specify Business Hours' | translate }}</span>

                    <div *ngIf="showOpeningHours">
                        <slm-input-opening-hours [formControl]="form.controls.opening_hours"></slm-input-opening-hours>
                        <slm-form-input-specific-opening-hours
                            [formControl]="form.controls.specific_opening_hours"
                            *ngIf="hasValidateOpeningHours"
                        ></slm-form-input-specific-opening-hours>
                    </div>
                </fieldset>

                <fieldset class="form-group-container payment-methods-container" [disabled]="readonly">
                    <legend>{{ 'Payment Methods' | translate }}</legend>
                    <div>
                        <app-input-payment-methods
                            [formControl]="form.controls.payment_methods"
                            [paymentMethods]="paymentMethods$ | async"
                            [paymentMethodsValue]="location.payment_methods"
                        ></app-input-payment-methods>
                    </div>
                </fieldset>

                <fieldset
                    *ngIf="isREProductType"
                    class="form-group-container additional-data-container"
                    [disabled]="readonly"
                >
                    <legend>{{ 'Additional Data' | translate }}</legend>
                    <div class="additional-data-fields">
                        <slm-app-input-text-multi
                            [formControl]="form.controls.services"
                            [label]="'Service'"
                        ></slm-app-input-text-multi>
                        <slm-app-input-text-multi
                            [formControl]="form.controls.products"
                            [label]="'Product'"
                        ></slm-app-input-text-multi>
                        <slm-app-input-text-multi
                            [formControl]="form.controls.brands"
                            [label]="'Brand'"
                        ></slm-app-input-text-multi>
                        <slm-app-input-text-multi
                            [formControl]="form.controls.associations"
                            [label]="'Association'"
                        ></slm-app-input-text-multi>
                        <slm-app-input-text-multi
                            [formControl]="form.controls.certifications"
                            [label]="'Certification'"
                        ></slm-app-input-text-multi>
                        <slm-app-input-text-multi
                            [formControl]="form.controls.customers"
                            [label]="'Customer'"
                        ></slm-app-input-text-multi>
                    </div>
                </fieldset>

                <fieldset class="form-group-container photo-n-video-container" [disabled]="readonly">
                    <legend>{{ 'Photos and Videos' | translate }}</legend>
                    <div>
                        <app-input-video
                            [formControl]="form.controls.videos"
                            [video]="location.videos"
                            [companyId]="location.company_id"
                        ></app-input-video>
                        <slm-input-image
                            [imageValue]="location.photos"
                            [control]="form.controls.photos"
                            [imageType]="'gallery'"
                            [label]="'Photos'"
                            [locationId]="location.id"
                            [multipleValue]="true"
                        ></slm-input-image>
                        <slm-input-image
                            [imageValue]="location.photos"
                            [control]="form.controls.photos"
                            [imageType]="'fb_cover'"
                            [locationId]="location.id"
                            [minHeight]="150"
                            [minWidth]="400"
                            [label]="'Add Facebook Cover (required 851px x 315px)'"
                            [multipleValue]="false"
                        ></slm-input-image>
                        <slm-input-image
                            [imageValue]="location.photos"
                            [control]="form.controls.photos"
                            [imageType]="'search_result'"
                            [locationId]="location.id"
                            [minHeight]="150"
                            [minWidth]="400"
                            [label]="'location_edit:add-pagesjaunes-thumbnail'"
                            [multipleValue]="false"
                        ></slm-input-image>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
