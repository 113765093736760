import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { HOST_APP } from '@slm/shared/environment';
import { Observable, filter, of } from 'rxjs';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

@Injectable({
    providedIn: 'root',
})
export class UserFacade {
    private readonly store = inject(Store);
    private readonly hostApp = inject(HOST_APP);

    readonly user$ = this.store.select(UserSelectors.selectUser).pipe(filter(user => !!user));

    fetchUser(): void {
        this.store.dispatch(UserActions.getUser());
    }

    selectBasePath(): Observable<string[]> {
        if (this.hostApp.isSlmMobile) {
            return of(['tabs']);
        }
        return this.store.select(UserSelectors.getAppBasePath);
    }

    selectBasePathAsString(): Observable<string> {
        if (this.hostApp.isSlmMobile) {
            return of('tabs');
        }
        return this.store.select(UserSelectors.getAppBasePathAsString);
    }

    logout(): void {
        this.store.dispatch(UserActions.logout());
    }
}
