import { HttpRequest } from '@angular/common/http';

export const getRedirectUri = (): string => {
    // We make sure when an onbehalf URL is called, we don't loose the onbehalf parameter
    if (window.location.href.includes('/#/app/2') || window.location.href.includes('onbehalf')) {
        return window.location.href;
    }

    return window.location.origin;
};

export const getRedirectUriBO = (): string => {
    const origin = window.location.origin;
    if (window.location.href.includes('/#/app/2') || window.location.href.includes('onbehalf')) {
        return window.location.href;
    }
    return origin + window.location.pathname;
};

export const getRedirectUriMobile = (): string => {
    const origin = window.location.origin;
    if (origin.includes('mobile.manager.solocal.com')) {
        return 'capacitor://mobile.manager.solocal.com';
    }

    return origin;
};

export const shouldAddToken = (request: HttpRequest<unknown>) => {
    const { method, url } = request;

    const isGetRequest = 'GET' === method.toUpperCase();
    const acceptablePaths = ['/assets'];
    const isAcceptablePathMatch = acceptablePaths.some(path => url.includes(path));

    return !(isGetRequest && isAcceptablePathMatch);
};
