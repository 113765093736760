import { Directive, Input, OnInit, inject } from '@angular/core';
import { UntypedFormArray, Validators } from '@angular/forms';
import { Location, Reference } from '@solocal-manager/sirius/support/base-models';
import { first, forEach, isUndefined } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TypeaheadCategoryService, UiService } from '../services';
import { WpmInputRepeatable } from './input-repeatable';

const MAX_GMB_CATEGORIES = 9;
const MAX_PROSPECT_PJ_ACTIVITIES = 3;

@Directive()
export abstract class WpmInputCategory extends WpmInputRepeatable implements OnInit {
    protected readonly typeaheadCategoryService = inject(TypeaheadCategoryService);
    protected readonly uiService = inject(UiService);

    activeInput: number;
    typeaheadLoading: boolean;
    typeaheadNoResults: boolean;

    isProspect: boolean;
    isEligibiliteDA: boolean;
    isOldOrOtherOrPPR: boolean;
    categories: Reference[];
    categories$: BehaviorSubject<Reference[]> = new BehaviorSubject([]);

    maxGmbCategories = MAX_GMB_CATEGORIES;
    maxProspectPjActivities = MAX_PROSPECT_PJ_ACTIVITIES;

    @Input()
    publisherId: string;

    private _locations;
    @Input() set locations(locations: Location[]) {
        this._locations = locations;
        if (locations) {
            this.isProspect = locations.some(location => this.uiService.isProspectLocation(location));
            this.isOldOrOtherOrPPR = locations.some(location => this.uiService.isOldOrOtherOrPPRLocation(location));
            this.isEligibiliteDA =
                this.isProspect || locations.some(location => this.uiService.isNewOfferLocation(location));
        }
    }

    get locations(): Location[] {
        return this._locations;
    }

    ngOnInit() {
        if (this.publisherId === 'pagesjaunes') {
            this.initData = {
                name: ['', [Validators.required]],
                id: ['', [Validators.required]],
                paid: ['free', []],
                eligibilite_da: [true, []],
            };
        } else {
            this.initData = {
                name: ['', [Validators.required]],
                id: ['', [Validators.required]],
            };
        }
        forEach(this.initData, (value, key) => {
            this._initDataValues[key] = value[0];
        });

        this.formArrayRepeatable = this.formBuilder.array([]);

        this.formRepeatable = this.formBuilder.group({
            data: this.formArrayRepeatable,
        });
        super.ngOnInit();
    }

    updateCategories(): void {
        this.activeInput !== undefined ? this.getCategories() : this.setCategories([]);
    }

    getCategories(): void {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        const userInput = repeatableControls.at(this.activeInput).value?.name;
        const savedCategories = this.formRepeatable.get('data').value;
        userInput?.length > 2
            ? this.typeaheadCategoryService
                  .getCategory(userInput, this.publisherId, this.isEligibiliteDA)
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(item => {
                      let list = item.filter(
                          (filtered: Reference) => !this.existsCategoryInCategories(savedCategories, filtered),
                      );
                      if (this.isOldOrOtherOrPPR && this.publisherId === 'pagesjaunes') {
                          list = list.filter((filtered: Reference) => filtered.paid === 'free');
                      }
                      this.setCategories(list);
                  })
            : this.setCategories([]);
    }

    setCategories(list: Reference[]): void {
        this.categories = list;
        this.categories$.next(list);
    }

    writeValue(value) {
        if (this.publisherId === 'pagesjaunes') {
            value.forEach(item => {
                if (isUndefined(item.eligibilite_da)) {
                    item.eligibilite_da = true;
                }
            });
        }
        super.writeValue(value);
    }

    existsCategoryInCategories(categories: Reference[], category: Reference): boolean | void {
        if (category) {
            return categories.map((cat: Reference) => cat.id).includes(category.id);
        }
    }

    changeTypeaheadLoading(e: boolean) {
        this.typeaheadLoading = e;
    }

    changeTypeaheadNoResults(e: boolean) {
        this.typeaheadNoResults = e;
    }

    typeaheadOnSelect(e) {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        const item = this.getValueFromOption(e);
        const selecteCategrpy = first(
            this.categories.filter((ref: Reference) => {
                return ref.id === item.id;
            }),
        );
        const value: Reference = selecteCategrpy?.paid
            ? {
                  ...item,
                  paid: selecteCategrpy.paid,
                  eligibilite_da: selecteCategrpy.eligibilite_da,
              }
            : item;
        repeatableControls.at(this.activeInput).setValue(value);
    }

    setActiveInput(index: number) {
        this.activeInput = index;
    }

    protected getValueFromOption(e): Reference {
        return e.item;
    }
}
