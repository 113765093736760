import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WpmInputRepeatable, createSubformValidator } from '@solocal-manager/sirius/core/class';
import { timeStepValidator } from '@solocal-manager/sirius/core/form/validators/time-step.validator';
import { ExceptionalHoursConfig } from '@solocal-manager/sirius/support/base-models';
import { groupBy, isEmpty, keys, pickBy } from 'lodash-es';
import * as momentInstance from 'moment';
import { extendMoment } from 'moment-range';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SpecificOpenningHoursValidator } from '../validators';

const moment = extendMoment(momentInstance);

type FormControlData<FORM_CONTROL_TYPE> = (FORM_CONTROL_TYPE | Validators[])[];
interface ExceptionalHoursData {
    start_date: FormControlData<string>;
    end_date: FormControlData<string>;
    timeSlots: FormArray<any>;
    closed: FormControlData<string>;
}

export interface ExceptionalHoursFormGroup {
    start_date: FormControl<string>;
    end_date: FormControl<string>;
    timeSlots: FormArray<any>;
    closed: FormControl<string>;
}
@Component({
    template: '',
})
export class WpmInputSpecificOpeningHoursComponent
    extends WpmInputRepeatable
    implements ControlValueAccessor, OnInit, OnDestroy
{
    private readonly translateService = inject(TranslateService);

    @Input()
    exceptionalHoursConfig: ExceptionalHoursConfig;

    readonly lang = this.translateService.currentLang;
    readonly today = new Date(moment().format());
    timeSlots = [];
    _initData: ExceptionalHoursData = {
        start_date: ['', [Validators.required]],
        end_date: [''],
        timeSlots: this.formBuilder.array([]),
        closed: ['', [Validators.required]],
    };
    destroyed$: Subject<boolean> = new Subject();
    propagateChange: (value: any) => void;

    ngOnInit(): void {
        combineLatest([this.formRepeatable.valueChanges, this.formRepeatable.statusChanges])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([controls, status]) => {
                this.propagateChange(controls);
            });
        this.validateFn = createSubformValidator(this.formRepeatable);
    }

    initInputRow(): FormGroup<ExceptionalHoursFormGroup> {
        return this.formBuilder.group({
            ...this.initData,
            timeSlots: this.formBuilder.array([]),
        });
    }

    set currentData(data: any) {
        this._currentData = this.convertToFormData(data);
    }

    get currentData(): ExceptionalHoursData {
        return this._currentData;
    }
    convertToFormData(data: any): ExceptionalHoursData[] {
        const groupByStartDate = groupBy(data, 'start_date');
        const formDataArray = [];
        const dataKeys = Object.keys(groupByStartDate);
        for (const value of dataKeys) {
            const val = groupByStartDate[value];
            const item = {
                start_date: val[0]['start_date'],
                end_date: val[0]['end_date'] ? val[0]['end_date'] : val[0]['start_date'],
                timeSlots: [],
                closed: val[0]['closed'],
            };
            if (val.length > 1) {
                val.forEach(element => {
                    item.timeSlots.push({
                        open_time: element.open_time,
                        close_time: element.close_time,
                    });
                });
            } else if (!item.closed) {
                item.timeSlots.push({
                    open_time: val[0].open_time,
                    close_time: val[0].close_time,
                });
            }
            formDataArray.push(item);
        }
        return formDataArray;
    }

    writeValue(newData: ExceptionalHoursData) {
        this.prepareWriteValue(newData);
    }

    prepareWriteValue(newData: ExceptionalHoursData) {
        this.currentData = newData;
        this.buildFormControls();
        const items = keys(
            pickBy(this.currentData, (item, index) => {
                if (item['timeSlots'].length > 0) {
                    return index;
                }
            }),
        );
        items.forEach(element => {
            if (!isEmpty(element)) {
                const timeSlotFormArray = this.formData.at(+element).get('timeSlots') as UntypedFormArray;
                const length = this.currentData[+element].timeSlots.length;
                for (let i = 0; i < length; i++) {
                    timeSlotFormArray.push(this.createSlot());
                }
            }
        });
        this.formRepeatable.setValue({ data: this.currentData });
        this.initOpeningHoursValidator();
        const controls = this.formData.controls.filter(
            c => c.get('closed').value === this.exceptionalHoursConfig.dateRange,
        ).length;
        if (controls === 0) {
            this.addOpeningHours();
        }
    }

    initOpeningHoursValidator() {
        const exceptionalHoursFormArray: FormArray<FormGroup<ExceptionalHoursFormGroup>> = this.formData;
        exceptionalHoursFormArray.controls.forEach(control => {
            control.setValidators(SpecificOpenningHoursValidator.create(this.formRepeatable));
        });
    }

    createSlot(timeSlot?: any) {
        const requireValidator = this.exceptionalHoursConfig.dateRange ? [] : [Validators.required];
        return this.formBuilder.group({
            open_time: [timeSlot ? timeSlot.open_time : '', { validators: [...requireValidator, timeStepValidator()] }],
            close_time: [
                timeSlot ? timeSlot.close_time : '',
                { validators: [...requireValidator, timeStepValidator()] },
            ],
        });
    }

    addOpeningHours() {
        const data = this.formData;
        const openingHours = this.formBuilder.group(
            {
                start_date: ['', [Validators.required]],
                end_date: [''],
                timeSlots: this.formBuilder.array([this.createSlot()]),
                closed: this.exceptionalHoursConfig.dateRange,
            },
            {
                validator: SpecificOpenningHoursValidator.create(this.formRepeatable),
            },
        );
        data.push(openingHours);
    }

    registerOnChange(fn) {
        this.propagateChange = newValue => {
            fn(this.transformValues(newValue));
        };
    }

    transformValues(newValue) {
        const fixedData = [];
        let objectData: any = {};
        newValue?.data.forEach(item => {
            if (item.closed) {
                objectData = {
                    start_date: item.start_date,
                    closed: item.closed,
                    end_date: item.end_date || item.start_date,
                };
                fixedData.push(objectData);
            } else {
                if (item?.timeSlots?.length === 0) {
                    fixedData.push(this.createTimeSlotObject(item, []));
                } else {
                    item?.timeSlots.forEach(element => {
                        fixedData.push(this.createTimeSlotObject(item, element));
                    });
                }
            }
        });

        return fixedData;
    }

    private createTimeSlotObject(data: any, timeSlot?: any) {
        return {
            closed: data.closed,
            start_date: data.start_date,
            close_time: timeSlot ? timeSlot.close_time || '' : data.close_time || '',
            open_time: timeSlot ? timeSlot.open_time || '' : data.open_time || '',
            end_date: data.end_date || '',
        };
    }

    removeTimeSlots(openingHoursIndex, timeSlotIndex) {
        const repeatableControls = this.formData;
        const openingHoursControls = repeatableControls.at(openingHoursIndex) as UntypedFormGroup;
        this.removeSlot(openingHoursControls, timeSlotIndex);
        openingHoursControls.updateValueAndValidity();
    }

    removeSlot(specificOpeningHours, timeSlotIndex) {
        const timeSlots = specificOpeningHours.controls.timeSlots as UntypedFormArray;
        timeSlots.removeAt(timeSlotIndex);
    }

    addDateInterval(openingHours) {
        const endDate = openingHours.get('end_date');
        endDate.setValue(this.getNextDay(this.today.toString()));
    }

    addTimeSlot(openingHoursIndex) {
        const repeatableControls = this.formData;
        const openingHoursControls = repeatableControls.at(openingHoursIndex) as UntypedFormGroup;
        const timeSlots = openingHoursControls.get('timeSlots') as UntypedFormArray;
        timeSlots.push(this.createSlot(this.createTimeSlots(timeSlots?.value || [])));
    }

    private createTimeSlots(timeSlots?: any): any {
        const isFirst = timeSlots?.length < 1;
        const startTime = isFirst ? 9 : 20;
        const endTime = isFirst ? 19 : 23;
        return {
            open_time: `${startTime < 10 ? '0' : ''}${startTime}:00`,
            close_time: `${endTime}:00`,
        };
    }

    removeOpeningHours(index?: number) {
        const openingHours = this.formData;
        openingHours.removeAt(index);
        openingHours.updateValueAndValidity();
    }

    openingChanged(event: any, control: AbstractControl, index: number) {
        const closed = control.get('closed');
        if (!closed.value) {
            this.addTimeSlot(index);
            const stateDate = control.get('start_date').value;
            control.get('end_date').setValue(stateDate);
        } else {
            control.get('end_date').setValue('');
            const timeSlots = <UntypedFormArray>control.get('timeSlots');
            timeSlots.controls = [];
            timeSlots.updateValueAndValidity();
        }
    }

    getNextDay(date: string) {
        return moment(date).add(1, 'days').format('YYYY-MM-DD');
    }

    updateEndDate(openingHours) {
        const closed = openingHours.get('closed');
        const start_date = openingHours.get('start_date');
        if (!closed.value) {
            openingHours.get('end_date').setValue(start_date.value);
        }
    }
    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
