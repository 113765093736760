import { Directive, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { find } from 'lodash-es';
import { WpmInputRepeatable } from '../../class';
import { ValidateUrl } from '../../validators';

const initConfig = {
    url: ['', [ValidateUrl.createValidator()]],
    type: ['website', []],
    display_url: [''],
    description: [''],
};

@Directive()
export abstract class WpmInputUrl extends WpmInputRepeatable {
    @Output() validationLaunched: EventEmitter<boolean> = new EventEmitter();

    keepOneInputOpen = true;
    currentValidation: any[];
    _initData = initConfig;

    constructor() {
        super();
        const cloneInitData = { ...initConfig };
        this._initData = cloneInitData;
    }

    addPrefixes(urls: Array<{ url: string }>) {
        urls.forEach((item: { url: string }, i: number) => {
            if (!item.url.startsWith('http://') && !item.url.startsWith('https://')) {
                urls[i].url = 'http://' + item.url;
            }
        });
    }

    hasUrlError(url: UntypedFormControl): boolean {
        return !!find(
            this.currentValidation,
            row =>
                !row.valid &&
                (row.url === url.value || row.url === 'http://' + url.value || row.url === 'https://' + url.value),
        );
    }

    getUrlError(url: UntypedFormControl) {
        return find(
            this.currentValidation,
            row =>
                !row.valid &&
                (row.url === url.value || row.url === 'http://' + url.value || row.url === 'https://' + url.value),
        );
    }
}
