import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Location } from '@solocal-manager/sirius/support/base-models';
import { InstantMessagingFacade } from '../data-access-chat.facade';
import { Conversation } from '../models';

@Component({
    template: '',
})
export class BaseChatComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    protected readonly instantMessagingFacade = inject(InstantMessagingFacade);

    conversations: Conversation[] = [];
    location: Location;
    hasChatActivated: boolean;
    currentPage = 1;
    count = null;
    isLastPage = false;
    isLoading = false;
    notActivatedChatIntegrations: string[];
    hideNotActivatedMessage = false;
    offset = 0;
    displayConv = false;
    isPluginInit = false;
    publisherChatList: string[] = [];
    readonly chatConfig$ = this.instantMessagingFacade.getChatConfig$;

    ngOnInit(): void {
        this.instantMessagingFacade.selectedLocation$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(location => {
            if (location?.id !== this.location?.id) {
                this.location = location;
                this.instantMessagingFacade.fetchSelectedLocationChatConfig(location.epj);
            }
        });
    }
}
