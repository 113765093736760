import { Component } from '@angular/core';

import { WpmFeedbackMessageHandlerComponent } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-feedback-message-handler',
    template: `
        <div class="feedback-message-handler">
            <div *ngFor="let alert of alerts">
                <alert [type]="alert.alertType" dismissible="true" [dismissOnTimeout]="alert.timeout">
                    <span [ngClass]="{ bold: isBold, strong: isStrong }">{{ alert.message | translate }}</span>
                </alert>
            </div>
        </div>
    `,
    styleUrls: ['./feedback-message-handler.component.scss'],
})
export class FeedbackMessageHandlerComponent extends WpmFeedbackMessageHandlerComponent {}
