import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, filter, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SlmAuthFacade {
    firstTimeTokenReceived$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
    constructor(private readonly keycloakService: KeycloakService) {
        this.tokenUpdater();
    }

    init(config: KeycloakOptions): Promise<boolean> {
        return this.keycloakService.init(config);
    }

    tokenUpdater(): void {
        this.keycloakService.keycloakEvents$.pipe(filter(e => e.type === KeycloakEventType.OnTokenExpired)).subscribe();
    }

    hasToken(): boolean {
        return !this.keycloakService.isTokenExpired();
    }

    async getToken(): Promise<string> {
        return await this.keycloakService.getToken();
    }

    logout(): void {
        this.keycloakService.logout();
    }

    getUserRoles(): string[] {
        return this.keycloakService.getUserRoles();
    }

    tryLogin(): Promise<void> {
        return this.keycloakService.login();
    }
}
