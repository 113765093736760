import { Component, forwardRef } from '@angular/core';
import {
    AbstractControl,
    AsyncValidator,
    NG_ASYNC_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';
import { ValidateUrl, WpmInputUrl } from '@solocal-manager/sirius/core/core';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'slm-input-url',
    templateUrl: './input-url.component.html',
    styleUrls: ['./input-url.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputUrlComponent),
            multi: true,
        },
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => InputUrlComponent),
            multi: true,
        },
    ],
})
export class InputUrlComponent extends WpmInputUrl implements AsyncValidator {
    initConfig = {
        url: ['', [ValidateUrl.createValidator()]],
        type: ['website', []],
        display_url: [''],
        description: [''],
        primary: [true],
    };

    validate(c: AbstractControl): Observable<ValidationErrors> {
        return of(null);
    }
}
