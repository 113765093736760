import { DestroyRef, Directive, Input, OnInit, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    AsyncValidator,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';
import { forEach, isEqual, last, uniqWith } from 'lodash-es';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { ValidateRepeatableLength } from '../validators';

export function createSubformValidator(form: UntypedFormGroup) {
    return (): Observable<ValidationErrors | null> => {
        if (form.status === 'PENDING') {
            return of(null);
        }

        const err = {
            subFormError: {
                valid: form.valid,
            },
        };

        return of(form.valid ? null : err);
    };
}

@Directive({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WpmInputRepeatable),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => WpmInputRepeatable),
            multi: true,
        },
    ],
})
export abstract class WpmInputRepeatable implements AsyncValidator, ControlValueAccessor, OnInit {
    protected readonly destroyRef = inject(DestroyRef);
    protected readonly formBuilder = inject(UntypedFormBuilder);

    @Input() checkForDuplicates = false;
    @Input() requiresOne = false;
    @Input() draggable = false;
    @Input() repeatableMaxLength: number;
    @Input() repeatableType: string;

    constructor() {
        if (this._initData) {
            for (const [key, value] of Object.entries(this._initData)) {
                this._initDataValues[key] = value[0];
            }
        }

        this.formArrayRepeatable = this.formBuilder.array([]);

        this.formRepeatable = this.formBuilder.group({
            data: this.formArrayRepeatable,
        });
    }

    set currentData(data: any) {
        this._currentData = data;
    }

    get currentData() {
        return this._currentData;
    }

    set initData(data: any) {
        this._initData = data;
    }

    get initData() {
        return this._initData;
    }

    get allowAddNew() {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        if (this.repeatableMaxLength && repeatableControls.length === this.repeatableMaxLength) {
            return false;
        }
        return true;
    }

    get formData() {
        return this.formRepeatable.controls.data as UntypedFormArray;
    }

    _initDataValues: object = {};
    _currentData: any;
    keepOneInputOpen = false;
    acceptEmptyInputs = false;
    formRepeatable: UntypedFormGroup;
    currentDragItem: number = null;
    formArrayRepeatable: UntypedFormArray;
    _hasFocus: number;
    cleanedData = [];
    _initData: object;
    destroyed$: Subject<boolean> = new Subject();

    propagateChange: any = () => {
        /***/
    };
    validateFn: (control: AbstractControl) => Observable<ValidationErrors | null> = () => {
        return of(null);
    };

    ngOnInit(): void {
        this.formArrayRepeatable.setValidators(
            ValidateRepeatableLength.createValidator(this.repeatableMaxLength, this.repeatableType),
        );
        combineLatest([this.formRepeatable.valueChanges, this.formRepeatable.statusChanges])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.propagateChange());
    }

    buildFormControls(): void {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

        let addCount = this.currentData.length - repeatableControls.length;

        if (addCount < 0) {
            while (addCount !== 0) {
                repeatableControls.removeAt(Math.abs(addCount) - 1);
                addCount++;
            }
        } else if (addCount > 0) {
            for (let i = 0; i < addCount; i++) {
                repeatableControls.push(this.initInputRow());
            }
        }
    }

    registerOnChange(fn): void {
        this.propagateChange = () => {
            this.cleanedData = [];
            const formRepeatable = this.formRepeatable.get('data') as UntypedFormArray;
            const initDataValues = this._initDataValues;

            formRepeatable.value.forEach((row, index) => {
                if (!isEqual(initDataValues, row) && formRepeatable.at(index).valid) {
                    this.cleanedData.push(row);
                }
            });

            return fn(this.cleanedData);
        };
    }

    registerOnTouched(): void {
        /***/
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        return this.validateFn(control);
    }

    initInputRow() {
        return this.formBuilder.group(this.initData);
    }

    removeInput(index: number) {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        repeatableControls.removeAt(index);

        if (this.keepOneInputOpen && repeatableControls.length === 0) {
            this.addInput(true);
        }
    }

    addInput(force?: boolean): UntypedFormGroup | void {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        if (force || this.formRepeatable.valid) {
            const formGroup = this.initInputRow();
            repeatableControls.push(formGroup);
            return formGroup;
        }
    }

    addEmptyInput() {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        const formGroup = this.initInputRow();
        repeatableControls.insert(0, formGroup);
    }

    unshiftInput() {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        repeatableControls.controls.unshift(this.initInputRow());
    }

    clearInput(index: number) {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

        repeatableControls.removeAt(index);
        const formGroup = this.initInputRow();
        repeatableControls.insert(index, formGroup);
    }

    writeValue(value) {
        this.currentData = value || [];

        if (this.checkForDuplicates && this.currentData.length) {
            this.currentData = uniqWith(this.currentData, isEqual);
        }

        this.buildFormControls();

        if (this.requiresOne && this.currentData.length === 0) {
            this.addEmptyInput();
        } else {
            this.formRepeatable.setValue({
                data: this.currentData,
            });
        }

        this.validateFn = createSubformValidator(this.formRepeatable);
    }

    mouseClick(index: number) {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

        if (repeatableControls.at(index).disabled === true) {
            repeatableControls.at(index).enable();
            this.propagateChange();
        }
    }

    isValid() {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;
        let valid = false;

        if (this.acceptEmptyInputs) {
            valid = true;
            return valid;
        }

        if (this.keepOneInputOpen) {
            const data = this.formRepeatable.value.data;
            const lastIndex = repeatableControls.controls.length - 1;
            forEach(repeatableControls.controls, (control, index) => {
                return index === lastIndex && !control.valid && isEqual(last(data), this._initDataValues)
                    ? true
                    : control.valid;
            });
        }

        return valid;
    }

    getFormValues() {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

        if (this.keepOneInputOpen) {
            const data = this.formRepeatable.value.data;

            if (isEqual(last(data), this._initDataValues)) {
                repeatableControls.removeAt(data.length - 1);
            }
        }

        return this.formRepeatable.get('data').value;
    }

    getCleanedFormValues() {
        const formValues = this.getFormValues();

        const cleanedForm = formValues.filter(value => {
            return !isEqual(value, this._initDataValues);
        });

        return cleanedForm;
    }

    dragHandleClicked(index: number) {
        this.currentDragItem = index;
    }

    reorderInputs(from: number, to: number) {
        const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

        if (from >= 0) {
            const fromValue = repeatableControls.at(from).value;
            const toValue = repeatableControls.at(to).value;

            repeatableControls.at(from).setValue(toValue);
            repeatableControls.at(to).setValue(fromValue);
        }
    }

    setFocus(index: number) {
        this._hasFocus = index;
    }

    hasFocus(index: number): boolean {
        return this._hasFocus === index;
    }
}
