import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { WpmInputPhone } from '@solocal-manager/sirius/core/core';

@Component({
    selector: 'slm-input-phones',
    templateUrl: './input-phones.component.html',
    styleUrls: ['./input-phones.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPhonesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputPhonesComponent),
            multi: true,
        },
    ],
})
export class InputPhonesComponent extends WpmInputPhone {}
