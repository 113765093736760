import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { WpmInputRepeatable } from '@solocal-manager/sirius/core/class';

@Component({
    selector: 'slm-input-email',
    templateUrl: './input-email.component.html',
    styleUrls: ['./input-email.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputEmailComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputEmailComponent),
            multi: true,
        },
    ],
})
export class InputEmailComponent extends WpmInputRepeatable {
    _initData = {
        email: ['', [Validators.required, Validators.email]],
        description: ['', []],
        type: ['contact', []],
    };
}
