import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WpmLocationsMapComponent, WpmReviewEditTextAreaComponent } from '@solocal-manager/sirius/core/core';
import { WpmPipesModule } from '@solocal-manager/sirius/core/pipes/pipes.module';
import { DataAccessChatModule } from '@solocal-manager/sirius/data-access-chat/data-access-chat.module';

@NgModule({
    imports: [CommonModule, DataAccessChatModule, WpmPipesModule],
    declarations: [WpmReviewEditTextAreaComponent, WpmLocationsMapComponent],
    exports: [WpmPipesModule],
    schemas: [],
})
export class WpmLibModule {}
