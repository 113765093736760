import { createReducer, on } from '@ngrx/store';

import { getUserError, getUserSuccess, logout } from './user.actions';
import { User } from './user.model';

export const USER_FEATURE_KEY = 'user';

export interface UserState {
    user: User;
    error?: Error;
}

export const initialState: UserState = {
    user: undefined,
};

export interface UserPartialState {
    readonly [USER_FEATURE_KEY]: UserState;
}

export const userReducer = createReducer<UserState>(
    initialState,
    on(getUserSuccess, (state, { user }) => ({ ...state, user })),
    on(getUserError, (state, { error }) => ({ ...state, error })),
    on(logout, () => initialState),
);
