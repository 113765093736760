import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SolocalButtonComponent } from '@shared/ui-components';

import {
    Capital,
    DailyMenuPrefixerPipe,
    DayTitlePipe,
    DisplayAliasPipe,
    ExcerptPipe,
    FromNowPipe,
    InfiniteScrollerDirective,
    InputImageErrorMessagesComponent,
    IsYoutubeEmbedUrlPipe,
    LocationCompletionRatePipe,
    LocationImagePipe,
    LocationSuggestionPipe,
    MouseOverAlertDirective,
    PropertyPipe,
    PublisherStatusStateDescriptionPipe,
    RemoveFromDom,
    TextToUrlPipe,
    TextWithParameterPipe,
    TimeSlotFormaterPipe,
    WpmFeedbackMessageHandlerComponent,
    WpmImageListComponent,
    WpmInputAddressComponent,
    WpmInputCombinedUrlsComponent,
    WpmInputErrorMessagesComponent,
    WpmInputImageUploadComponent,
    WpmInputOpeningHoursComponent,
    WpmInputServiceAreaComponent,
    WpmInputSpecificOpeningHoursComponent,
    WpmInputVideoComponent,
    WpmLocationListComponent,
    WpmLocationListItemComponent,
} from '@solocal-manager/sirius/core/core';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, SolocalButtonComponent],
    declarations: [
        DailyMenuPrefixerPipe,
        LocationImagePipe,
        IsYoutubeEmbedUrlPipe,
        DayTitlePipe,
        PropertyPipe,
        PublisherStatusStateDescriptionPipe,
        FromNowPipe,
        TextToUrlPipe,
        LocationCompletionRatePipe,
        LocationSuggestionPipe,
        ExcerptPipe,
        TimeSlotFormaterPipe,
        Capital,
        InfiniteScrollerDirective,
        RemoveFromDom,
        MouseOverAlertDirective,
        WpmLocationListComponent,
        WpmLocationListItemComponent,
        WpmImageListComponent,
        WpmFeedbackMessageHandlerComponent,
        WpmInputImageUploadComponent,
        WpmInputAddressComponent,
        WpmInputServiceAreaComponent,
        WpmInputOpeningHoursComponent,
        WpmInputSpecificOpeningHoursComponent,
        WpmInputVideoComponent,
        WpmInputErrorMessagesComponent,
        WpmInputCombinedUrlsComponent,
        InputImageErrorMessagesComponent,
        TextWithParameterPipe,
        DisplayAliasPipe,
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        DailyMenuPrefixerPipe,
        LocationImagePipe,
        IsYoutubeEmbedUrlPipe,
        DayTitlePipe,
        PropertyPipe,
        PublisherStatusStateDescriptionPipe,
        FromNowPipe,
        TextToUrlPipe,
        LocationCompletionRatePipe,
        LocationSuggestionPipe,
        ExcerptPipe,
        TimeSlotFormaterPipe,
        TextWithParameterPipe,
        DisplayAliasPipe,
        Capital,
        InfiniteScrollerDirective,
        RemoveFromDom,
        MouseOverAlertDirective,
        WpmLocationListComponent,
        WpmLocationListItemComponent,
        WpmImageListComponent,
        WpmFeedbackMessageHandlerComponent,
        WpmInputImageUploadComponent,
        WpmInputVideoComponent,
        WpmInputErrorMessagesComponent,
        InputImageErrorMessagesComponent,
        SolocalButtonComponent,
    ],
    schemas: [],
})
export class WpmSharedModule {}
