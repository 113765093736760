import { Directive, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms';
import { EPhoneType, Phone, PhoneType } from '@solocal-manager/sirius/support/base-models';
import { isEqual, uniqWith } from 'lodash-es';
import { WpmInputRepeatable } from '../class';
import { countryCodes } from '../config';
import { isFrenchShortNumber } from '../utils';
import {
    PhoneNumberValidator,
    ValidateMaxMobilePhone,
    ValidateOneStandardPhone,
    ValidatePrice,
    ValidateRepeatableLength,
} from '../validators';
import { NumberOnlyValidator } from '../validators/number-only.validator';

@Directive()
export abstract class WpmInputPhone extends WpmInputRepeatable {
    @Input() set country(country: string) {
        if (country) {
            this.defaultCountryCode = this.countryCodes?.find(item => item.country === country.toLowerCase())?.code;
        }
    }

    defaultToggleType = true;
    defaultCountryCode = '33';

    keepOneInputOpen = true;

    phoneTypes: PhoneType[] = [EPhoneType.main, EPhoneType.fax];

    countryCodes = countryCodes.map(item => ({
        code: item.code,
        country: item.country.toLowerCase(),
    }));

    _initData = {
        number: [
            null,
            [
                Validators.required,
                NumberOnlyValidator.create(),
                PhoneNumberValidator.create(countryCodes.find(item => item.code === this.defaultCountryCode)?.country),
            ],
        ],
        type: [EPhoneType.main, [Validators.required]],
        pricingPrice: [null],
        pricingType: [null],
        type_line: [null],
        togglePricing: [false],
        toggleTypeLine: [this.defaultToggleType],
        country_code: [this.defaultCountryCode, [Validators.required]],
    };

    get priceValidator() {
        return ValidatePrice.createValidator();
    }

    writeValue(value) {
        if (value) {
            const phoneData = value.map(data => {
                const obj = {
                    ...data,
                    pricingPrice: data.pricing.price,
                    pricingType: data.pricing.type,
                    togglePricing: !!data.pricing.price || !!data.pricing.type,
                    toggleTypeLine: !!data.type_line || this.defaultToggleType,
                    country_code: data.country_code || this.defaultCountryCode,
                };

                delete obj.pricing;

                return obj;
            });

            super.writeValue(phoneData);

            const validators = [ValidateOneStandardPhone.createValidator(), ValidateMaxMobilePhone.createValidator()];
            if (this.repeatableMaxLength) {
                validators.push(ValidateRepeatableLength.createValidator(this.repeatableMaxLength));
            }
            this.formRepeatable.get('data').setValidators(validators);

            const repeatableControls = this.formRepeatable.controls.data as UntypedFormArray;

            for (const control of repeatableControls.controls) {
                this.setValidatorsForNumber(control as UntypedFormGroup);
            }
        }
    }

    registerOnChange(fn) {
        super.registerOnChange(fn);
        const originalPropagateChange = this.propagateChange;

        this.propagateChange = () => {
            originalPropagateChange();
            let clearData = this.cleanedData.map(data => {
                const obj = {
                    ...data,
                    pricing: {
                        price: data.togglePricing ? parseFloat(data.pricingPrice) : null,
                        type: data.togglePricing ? data.pricingType : null,
                    },
                    type_line: data.toggleTypeLine ? data.type_line : null,
                };

                delete obj.togglePricing;
                delete obj.toggleTypeLine;
                delete obj.pricingPrice;
                delete obj.pricingType;

                return obj;
            });

            if (this.checkForDuplicates) {
                clearData = uniqWith(clearData, isEqual);
            }

            return fn(clearData);
        };
    }

    setValidatorsForNumber(phone: UntypedFormGroup) {
        const country = this.countryCodes.find(item => item.code === phone.get('country_code').value)?.country;
        const validators = [Validators.required, NumberOnlyValidator.create(), PhoneNumberValidator.create(country)];
        phone.get('number').setValidators(validators);
        phone.get('number').updateValueAndValidity();
    }

    get formArrayValues() {
        return this.formRepeatable.controls.data.value;
    }

    get maxPhoneNumbersGained(): boolean {
        return !this.repeatableMaxLength ? false : this.formArrayValues.length >= this.repeatableMaxLength - 1;
    }

    codeChanged(phone: UntypedFormGroup) {
        this.setValidatorsForNumber(phone);
    }

    typeChanged(phone: UntypedFormGroup) {
        if (phone.get('type').value === EPhoneType.shortNumber) {
            if (!isFrenchShortNumber(phone.get('number').value)) {
                phone.get('number').setValue(null);
            }
            phone.get('country_code').setValue(this.defaultCountryCode);
        }
    }

    addNewPhone() {
        if (!this.maxPhoneNumbersGained) {
            const newNumber = this.addInput();
            if (newNumber) {
                this.setValidatorsForNumber(newNumber);
            }
        }
    }

    get mainShortNumber(): boolean {
        return (
            (this.formArrayValues[0].type === EPhoneType.shortNumber ||
                isFrenchShortNumber(this.formArrayValues[0].number)) &&
            !this.formArrayValues.some(
                (phone: Phone) => phone.type === EPhoneType.main && !isFrenchShortNumber(phone.number),
            )
        );
    }
}
