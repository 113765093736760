import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { WpmInputRepeatable } from '@solocal-manager/sirius/core/class';

@Component({
    selector: 'slm-app-input-text-multi',
    templateUrl: './input-text-multi.component.html',
    styleUrls: ['./input-text-multi.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextMultiComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputTextMultiComponent),
            multi: true,
        },
    ],
})
export class InputTextMultiComponent extends WpmInputRepeatable implements OnInit {
    @Input() label: string;
    _initData = {
        text: ['', [Validators.required]],
    };

    ngOnInit(): void {
        this.formRepeatable.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(controls => this.propagateChange(controls));
    }

    set currentData(data: string[]) {
        const fixedData = [];
        data.forEach(row => fixedData.push({ text: row }));
        this._currentData = fixedData;
    }

    get currentData() {
        return this._currentData;
    }

    registerOnChange(fn) {
        this.propagateChange = function (newVal) {
            const fixedData = [];

            if (newVal && newVal.data) {
                newVal.data.forEach((row: any) => {
                    fixedData.push(row.text);
                });

                return fn(fixedData);
            }
        };
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.formData.controls, event.previousIndex, event.currentIndex);

        const value = { data: this.formData.value };
        moveItemInArray(value.data, event.previousIndex, event.currentIndex);
        this.propagateChange(value);
    }
}
