import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { createSubformValidator } from '@solocal-manager/sirius/core/class';
import { WpmInputRepeatable } from '@solocal-manager/sirius/core/class/input-repeatable';
import { OverlappingOpeningHoursValidator } from '@solocal-manager/sirius/core/form';
import { timeStepValidator } from '@solocal-manager/sirius/core/form/validators/time-step.validator';
import { Day } from '@solocal-manager/sirius/core/types/days';
import { toPascalCase } from '@solocal-manager/sirius/core/utils/string.utils';
import { TimePeriod } from '@solocal-manager/sirius/support/base-models';
import { filter, groupBy } from 'lodash-es';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface InputOpeningHours {
    open_day: string;
    close_day: string;
    openingHours: OpeningHourTimeSlot[];
    closed: boolean;
    isFullTime: boolean;
}

export interface OpeningHourTimeSlot {
    open_time: string;
    close_time: string;
}

export interface OpeningHourTimeSlotFormGroup {
    open_time: FormControl<string>;
    close_time: FormControl<string>;
}

export interface InputOpeningHoursFormGroup {
    open_day: FormControl<string>;
    close_day: FormControl<string>;
    openingHours: FormArray<FormGroup<OpeningHourTimeSlotFormGroup>>;
    closed: FormControl<boolean>;
    isFullTime: FormControl<boolean>;
}

const stepSize = 5;

@Component({
    selector: 'slm-wpm-input-opening-hours',
    template: '',
})
export class WpmInputOpeningHoursComponent extends WpmInputRepeatable implements OnInit {
    days: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    timeSlots = [];
    _initData = {
        open_day: ['', []],
        close_day: ['', []],
        openingHours: this.formBuilder.array([]),
        closed: ['', []],
        isFullTime: ['', []],
    };
    destroyed$: Subject<boolean> = new Subject();
    propagateChange: any = () => {
        return void 0;
    };

    ngOnInit(): void {
        combineLatest([this.formRepeatable.valueChanges, this.formRepeatable.statusChanges])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([controls, status]) => {
                this.propagateChange(controls);
            });
    }

    set currentData(data: any) {
        this._currentData = data;
    }

    get currentData() {
        return this._currentData;
    }

    addRowForDay(day: Day): FormGroup<InputOpeningHoursFormGroup> {
        const newRow = {
            open_day: ['', []],
            close_day: ['', []],
            openingHours: this.setOpeningHoursFormArray(day),
            closed: [true, []],
            isFullTime: [false, []],
        };
        return this.formBuilder.group(newRow);
    }

    writeValue(value: any) {
        const timePeriods = value as TimePeriod[];
        this.currentData = this.convertToFormData(timePeriods || []);
        this.buildFormControls();
        this.formRepeatable.setValue({ data: this.currentData });
        this.validateFn = createSubformValidator(this.formRepeatable);
        this.initOpeningHoursValidator();
    }

    buildFormControls() {
        const repeatableControls: FormArray<FormGroup<InputOpeningHoursFormGroup>> = this.formRepeatable.controls
            .data as FormArray<FormGroup<InputOpeningHoursFormGroup>>;

        this.days.forEach(day => {
            repeatableControls.push(this.addRowForDay(day));
        });
    }

    initOpeningHoursValidator() {
        const repeatableControls = this.formData as FormArray<FormGroup<any>>;
        repeatableControls.controls.forEach(control => {
            control.setValidators(OverlappingOpeningHoursValidator.create());
        });
    }

    convertToFormData(timePeriods: TimePeriod[]): InputOpeningHours[] {
        const groupByOpenDay = groupBy(timePeriods, 'open_day');
        const formDataArray: InputOpeningHours[] = [];
        console.log({ data: timePeriods, groupByOpenDay });
        this.days.forEach(day => {
            const dayTimePeriods: TimePeriod[] = groupByOpenDay[day.toUpperCase()];
            if (dayTimePeriods?.length > 0) {
                const dayInputOpeningHour = this.convertDayTimePeriodsToInputOpeningHours(dayTimePeriods);
                formDataArray.push(dayInputOpeningHour);
            } else {
                formDataArray.push({
                    open_day: day,
                    close_day: day,
                    openingHours: [],
                    closed: true,
                    isFullTime: false,
                });
            }
        });

        return formDataArray;
    }

    private convertDayTimePeriodsToInputOpeningHours(dayTimePeriods: TimePeriod[]): InputOpeningHours {
        const firstPeriod = dayTimePeriods[0];
        const item: InputOpeningHours = {
            open_day: toPascalCase(firstPeriod.open_day),
            close_day: toPascalCase(firstPeriod.close_day) ?? '',
            openingHours: dayTimePeriods.reduce(
                (acc: OpeningHourTimeSlot[], cur) => [
                    ...acc,
                    {
                        open_time: cur.open_time,
                        close_time: cur.close_time,
                    },
                ],
                [],
            ),
            isFullTime: firstPeriod?.close_time === '24:00' && firstPeriod?.open_time === '00:00',
            closed: false,
        };
        return item;
    }

    private setOpeningHoursFormArray(day: Day): FormArray<FormGroup<OpeningHourTimeSlotFormGroup>> {
        const openingHoursFormArray: FormArray<FormGroup<OpeningHourTimeSlotFormGroup>> = this.formBuilder.array([]);
        const existingInputOpeningHours = filter(
            { ...(this.currentData as InputOpeningHours[]) },
            inputOpeningHours => {
                return inputOpeningHours.open_day.toLowerCase() === day.toLowerCase();
            },
        );
        if (existingInputOpeningHours?.length > 0) {
            const length = existingInputOpeningHours[0].openingHours.length;
            for (let i = 0; i < length; i++) {
                openingHoursFormArray.push(this.createOpeningHours());
            }
            if (existingInputOpeningHours[0].isFullTime) {
                openingHoursFormArray.disable();
            }
        }
        return openingHoursFormArray;
    }

    openCloseDay(event: any, control: FormGroup<InputOpeningHoursFormGroup>, index: number) {
        const closed = control.controls.closed;
        const timeSlots = control.controls.openingHours;
        const close_day = control.controls.close_day;
        if (!closed.value) {
            timeSlots.push(
                this.createOpeningHours(this.createTimeSlots((timeSlots?.value as OpeningHourTimeSlot[]) || [])),
            );
        } else {
            timeSlots.controls = [];
            control.controls.isFullTime.setValue(false);
            close_day.setValue(control.get('open_day').value);
            timeSlots.updateValueAndValidity();
        }
    }

    removeOpeningHours(openingHours, hoursIndex) {
        openingHours.removeAt(hoursIndex);
    }

    private createOpeningHours(openingHours?: OpeningHourTimeSlot): FormGroup<OpeningHourTimeSlotFormGroup> {
        return this.formBuilder.group({
            open_time: [openingHours?.open_time ?? '', timeStepValidator(stepSize)],
            close_time: [openingHours?.close_time ?? '', timeStepValidator(stepSize)],
        });
    }

    addOpeningHours(openingHours) {
        openingHours.push(this.createOpeningHours(this.createTimeSlots(openingHours?.value || [])));
    }

    private createTimeSlots(timeSlots?: OpeningHourTimeSlot[]): OpeningHourTimeSlot {
        const isFirst = timeSlots?.length < 1;
        const startTime = isFirst ? 9 : 20;
        const endTime = isFirst ? 19 : 23;
        return {
            open_time: `${startTime < 10 ? '0' : ''}${startTime}:00`,
            close_time: `${endTime}:00`,
        };
    }

    registerOnChange(fn) {
        this.propagateChange = newValue => this.transformValue(newValue, fn);
    }

    transformValue({ data }: { data: InputOpeningHours[] }, fn?) {
        const periods: TimePeriod[] = [];
        data.forEach(openingHours => {
            if (!openingHours.closed) {
                if (openingHours.isFullTime) {
                    periods.push({
                        open_day: openingHours.open_day.toUpperCase(),
                        close_day: openingHours.close_day.toUpperCase(),
                        open_time: '00:00',
                        close_time: '24:00',
                    });
                } else {
                    openingHours?.openingHours?.forEach(openingHourTimeSlot => {
                        periods.push({
                            open_day: openingHours.open_day.toUpperCase(),
                            close_day: openingHours.close_day.toUpperCase(),
                            open_time: openingHourTimeSlot.open_time,
                            close_time: openingHourTimeSlot.close_time,
                        });
                    });
                }
            }
        });
        return fn ? fn({ periods }) : { periods };
    }
}
